<template>
  <div class="ciam-pagination-container">
    <div>
      <i18n path="pagination.info" tag="p" class="ciam-pagination-info">
        <template v-slot:page>
          <ciam-text class="medium">{{ pageCurrent }}</ciam-text>
        </template>
        <template v-slot:pages>
          <ciam-text class="medium">{{ pageCount }}</ciam-text>
        </template>
        <template v-slot:results>
          <ciam-text class="medium">{{ resultCount }}</ciam-text>
        </template>
      </i18n>
    </div>
    <div>
      <nav class="ciam-pagination-pages" aria-label="Pagination">
        <ciam-button class="white left" :disabled="pageCurrent === 1" @click="$emit('change', pageCurrent - 1)">
          <span class="sr-only">{{ $t('pagination.previous') }}</span>
          <ciam-icon name="fa-chevron-left"></ciam-icon>
        </ciam-button>
        <ciam-button
          class="white right"
          :disabled="pageCurrent === pageCount"
          @click="$emit('change', pageCurrent + 1)"
        >
          <span class="sr-only">{{ $t('pagination.next') }}</span>
          <ciam-icon name="fa-chevron-right"></ciam-icon>
        </ciam-button>
      </nav>
    </div>
  </div>
</template>

<script>
import CiamText from '@/components/CiamText';
import CiamIcon from '@/components/CiamIcon';
import CiamButton from '@/components/CiamButton';

export default {
  name: 'ciam-pagination',
  components: {
    CiamButton,
    CiamIcon,
    CiamText,
  },
  data() {
    return {};
  },
  props: {
    displayedSlice: {
      type: Number,
      default: 5,
    },
    pageCurrent: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    resultCount: {
      type: Number,
      required: true,
    },
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-pagination-container {
  @apply hidden sm:flex-1 sm:flex sm:items-center sm:justify-between;
}

.ciam-pagination-info {
  @apply text-sm text-gray-700;
}

.ciam-pagination-pages {
  @apply relative z-0 inline-flex -space-x-px;
}
</style>
