<template>
  <div class="pricing-plan">
    <img :src="planImage" :alt="planName" />
    <span>{{ planLabel }}</span>
  </div>
</template>

<script>
import DeploymentBusiness from '@/pages/deployments/DeploymentBusiness';

export default {
  name: 'PricingBadge',
  props: {
    planName: {
      required: true,
      type: String,
    },
    planLabel: {
      required: false,
      type: String,
    },

  },

  data() {
    return {
      planImage: '',
    };
  },

  async created() {
    const definitivePlanId = DeploymentBusiness.getPlanIdFromLegacy(this.planName);
    this.planImage = DeploymentBusiness.getImageFromPlanId(definitivePlanId);
  },
};
</script>

<style scoped style="scss">
div.pricing-plan {
  display: flex;
  align-items: center;
}

div.pricing-plan img {
  height: 32px;
  border-radius: 4px;
  margin-right: 10px;
}
</style>
