<template>
  <ciam-page :title-suffix="$t('pageTitles.deployments')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>{{ $t('deploymentList.header') }}</template>
        <template v-slot:subtitle>{{ $t('deploymentListSubtitle') }}</template>
        <template v-slot:actions>
          <ciam-button
            class="primary"
            data-e2e="deployment-new"
            data-trkciam-action="deployment"
            data-trkciam-category="conversion"
            data-trkciam-name="click create new deployment"
            :to="{ name: $routes.DeploymentCreate }"
          >
            {{ $t('deploymentList.create') }}
            <template v-slot:left>
              <ciam-icon name="fa-plus"></ciam-icon>
            </template>
          </ciam-button>
        </template>
      </ciam-card-header>
      <ciam-card-content-line type="full-width" v-if="problem">
        <template v-slot:content>
          <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
        </template>
      </ciam-card-content-line>
    </ciam-card>

    <ciam-card class="mt-6">
      <ciam-card-content>
        <div class="flex flex-row justify-between">
          <div class="mt-5 ml-5 mb-5 ">
            <div class="inline-flex items-center block" v-if="$keycloak.hasRealmRole('ADMIN')">
              <div class="w-96">
                <ciam-input
                  type="text"
                  :debounceTimeout="1000"
                  v-model="name"
                  @change-debounced="refreshList()"
                  date-e2e="refresh-deployment-list"
                  :placeholder="$t('deploymentList.filter')"
                ></ciam-input>
              </div>
              <div class="w-24 ml-2">
                <ciam-checkbox v-model="includeAll" @input="updateIncludeAll()" label="All"></ciam-checkbox>
              </div>
            </div>
          </div>
          <div class="mt-5 mr-5">
            <ciam-button class="primary" @click="refreshList()">
              <ciam-icon name="fa-sync-alt"></ciam-icon>
            </ciam-button>
          </div>
        </div>

        <b-table
          style="clear: both"
          :striped="true"
          :hoverable="true"
          :data="data"
          :total="totalElements"
          :per-page="size"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          :row-class="(row) => (row.highlighted ? 'highlight' : '') + 'deployment--row'"
          backend-sorting
          backend-pagination
          :aria-next-label="$t('nextPage')"
          :aria-previous-label="$t('previousPage')"
          :aria-page-label="$t('page')"
          :aria-current-label="$t('currentPage')"
          @page-change="onPageChange"
          @sort="onSort"
        >
          <b-table-column field="name" :label="$t('deployment.name')" sortable v-slot="props">
            <ciam-button
              :data-e2e="'deployment-name-' + props.row.name"
              :to="{ name: $routes.DeploymentDetails, params: { id: props.row.id } }"
              >{{ props.row.name }}
            </ciam-button>
            <status-badge :status="props.row.status" v-if="props.row.status !== 'RUNNING'" />
          </b-table-column>
          <b-table-column field="plans.name" :label="$t('deployment.plan')" sortable v-slot="props">
            <pricing-badge :planName="props.row.pricingPlan.name" :planLabel="props.row.pricingPlan.label" />
          </b-table-column>

          <b-table-column field="usersCount" :label="$t('deployment.nbUsers')" v-slot="props">
            <span v-if="props.row.statistics" class="is-centered"
              ><strong v-text="props.row.statistics.usersCount" /> /
              <strong v-text="props.row.statistics.maxUsersCountPerRealm"
            /></span>
            <span v-else><strong>~</strong> / <strong>~</strong></span>
          </b-table-column>

          <b-table-column field="realmsCount" :label="$t('deployment.nbRealms')" v-slot="props">
            <span v-if="props.row.statistics" class="is-centered"
              ><strong v-text="props.row.statistics.realmsCount" /> /
              <strong v-text="props.row.statistics.maxRealmsCount"
            /></span>
            <span v-else><strong>~</strong> / <strong>~</strong></span>
          </b-table-column>

          <b-table-column v-slot="props">
            <template>
              <div style="display: flex; justify-content: flex-end">
                <ciam-button
                  class="secondary mr-2"
                  :disabled="!props.row.statistics || !props.row.statistics.adminUrl"
                  @click="openConsole(props.row.statistics.adminUrl)"
                >
                  {{ $t('deployment.openConsole') }}
                  <template v-slot:right>
                    <ciam-icon name="fa-up-right-from-square"></ciam-icon>
                  </template>
                </ciam-button>
              </div>
            </template>
          </b-table-column>
          <!-- Template : contenu du tableau lorsqu'il n'y a pas de données -->
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <!-- In case where the first page is not loaded yet, we have to check if _embedded is defined -->
                <p v-if="name == ''">{{ $t('deploymentList.emptyText') }}</p>
                <p v-else>{{ $t('deploymentList.noDeploymentName') }}</p>
                <p>
                  <ciam-button class="primary" :to="{ name: $routes.DeploymentCreate }">
                    {{ $t('deploymentList.create') }}
                    <template v-slot:left>
                      <ciam-icon name="fa-plus"></ciam-icon>
                    </template>
                  </ciam-button>
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </ciam-card-content>
      <ciam-card-footer>
        <ciam-pagination
          v-if="data.length > 0"
          :page-current="page"
          :page-count="totalPages"
          :result-count="totalElements"
          @change="onPageChange($event)"
        ></ciam-pagination>
      </ciam-card-footer>
    </ciam-card>
  </ciam-page>
</template>

<script>
import DeploymentService from './DeploymentService';
import DeploymentStatusService from './DeploymentStatusService';
import StatusBadge from '@/components/StatusBadge';
import PricingBadge from '@/components/PricingBadge';
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamButton from '@/components/CiamButton';
import { Notification } from 'vue-notifyjs';
import { bus } from '@/main';
import CiamIcon from '@/components/CiamIcon';
import CiamCardContent from '@/components/CiamCardContent';
import CiamPagination from '@/components/CiamPagination';
import CiamCardFooter from '@/components/CiamCardFooter';
import CiamInput from '@/components/CiamInput';
import { intOr } from '@/util/fp';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import CiamCheckbox from '@/components/themes_templates/CiamCheckbox.vue';
import { update } from 'ramda';

export default {
  name: 'DeploymentList',
  components: {
    CiamCheckbox,
    CiamInput,
    CiamCardFooter,
    CiamCardContent,
    StatusBadge,
    PricingBadge,
    CiamCard,
    CiamCardHeader,
    CiamButton,
    CiamIcon,
    CiamPagination,
    CiamAlert,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      service: new DeploymentService(),
      data: [],
      name: this.$route.query.name || '',
      includeAll: true,
      totalElements: 0,
      totalPages: 0,
      sortField: this.$route.query.sortField || 'name',
      sortOrder: this.$route.query.sortOrder || 'asc',
      defaultSortOrder: 'asc',
      page: intOr(1, this.$route.query.page),
      size: intOr(15, this.$route.query.size),
      problem: null,
    };
  },
  mounted: function () {
    const preference = localStorage.getItem('cloud-iam.includeAll');
    if (preference) {
      this.includeAll = preference === 'true';
    }

    this.refreshList();
    this.initDeploymentStatusListener();
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
  },
  methods: {
    update,
    findFirst(array, predicate) {
      for (const index in array) {
        const item = array[index];
        if (predicate(item)) return { item, index };
      }
    },
    initDeploymentStatusListener() {
      bus.on('deployment-status-received', async ({ id, status }) => {
        if (status) {
          // On récupère la position dans le tableau du déploiement
          const found = this.findFirst(this.data, (d) => d.id === id);

          if (!found) {
            // findFirst can yield undefined
            return;
          }

          // On récupère le déploiement à jour
          const deployment = await DeploymentService.get(found.item.id);

          /* On utilise Array.splice pour modifier l'élément dans le tableau afin que Vue puisse
             détecter ce changement et mettre à jour l'UI */
          deployment.highlighted = true;
          this.data.splice(found.index, 1, deployment);
        }
      });
    },

    updateIncludeAll: async function () {
      localStorage.setItem('cloud-iam.includeAll', this.includeAll);
      return this.refreshList();
    },
    refreshList: async function () {
      let loader = this.$loading.show();
      try {
        this.data = [];

        this.$router.ciamUpdateQuery({
          page: this.page,
          size: this.size,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          name: this.name,
        });

        const data = await DeploymentService.getAll({
          page: this.page,
          size: this.size,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          name: this.name,
          organization_id: this.$route.query.organization_id,
          dedicated: this.isAdmin ? !this.includeAll : false,
        });

        const deployments = (data && data._embedded && data._embedded.deployments) || [];
        if (!deployments) {
          Notification.notify({
            message: this.$t('notifications.unknown'),
            type: 'danger',
          });
          return;
        }
        deployments.forEach((d) => {
          d.statistics = {}; // cannot create the attribute outside the object
          this.getStats(d);
        });

        DeploymentStatusService.checkDeployments(deployments);
        this.data = [...deployments];
        if (data.page) {
          this.totalElements = data.page.totalElements;
          this.totalPages = data.page.totalPages;
        }
      } catch (err) {
        if (err != undefined) this.$log.error(err);
        this.totalElements = 0;
      } finally {
        loader.hide();
      }
    },

    async getStats(deployment) {
      deployment.statistics = await DeploymentService.getStatistics(deployment).catch((error) => {
        this.problem = deployment.status != 'CREATING' ? error : null;
      });
    },

    openConsole(adminUrl) {
      window.open(adminUrl);
    },

    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
  },
};
</script>

<style lang="scss">
tr.deployment--row td {
  height: 65px;
  vertical-align: middle;
}
</style>